import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return <Layout>
    
    <div class="services-wrapper">
      <div class="content">
        <div>
          <h1>What we do</h1>
          <ul class="services">
            <li><h3>All Plumbing and Gas</h3></li>
            <li><h3>Hot Water Repairs/Replacement</h3></li>
            <li><h3>Gas Appliance Installations</h3></li>
            <li><h3>Tap &amp; Toilet Repairs/Replacement</h3></li>
            <li><h3>Blocked Drains</h3></li>
            <li><h3>Water Bursts</h3></li>
            <li><h3>Bathroom Renovations</h3></li>
            <li><h3>Leak Detection</h3></li>
          </ul>
          <h2>We service all of metropolitan Adelaide, the Adelaide Hills, southern Adelaide, and the Fleurieu region.</h2>
          <div class="right">
            <h2><em>... How can we help you?</em></h2>
          </div>

        </div>
      </div>
    </div>
  </Layout>

}
